import React from 'react';
import { StaticRouter, BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-common';
import App from './App';
import catalogEN from '../locales/en/messages.js';
import { I18nProvider } from '@lingui/react';

const catalogs = {
  en: catalogEN,
};

export default ({ client, store, fetch, location, context }) => {
  if (process.env.BUILD_TARGET === 'server') {
    return (
      <I18nProvider language="en" catalogs={catalogs}>
        <ApolloProvider client={client}>
          <ReduxProvider store={store}>
            <StaticRouter location={location} context={context}>
              <App context={{ client, store, fetch, location }} />
            </StaticRouter>
          </ReduxProvider>
        </ApolloProvider>
      </I18nProvider>
    );
  }

  return (
    <I18nProvider language="en" catalogs={catalogs}>
      <ApolloProvider client={client}>
        <ReduxProvider store={store}>
          <BrowserRouter>
            <App context={{ store, fetch, client, location }} />
          </BrowserRouter>
        </ReduxProvider>
      </ApolloProvider>
    </I18nProvider>
  );
};
